.skills-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: Gupter;
  font-size: large;
  line-height: 25px;
  text-align: justify;
}

@media (max-width: 768px) {
  .skills-container {
    text-align: left;
    font-size: medium;
  }
}

.skills-title {
  font-family: Gupter;
  font-size: larger;
  margin-bottom: 0;
  text-decoration: underline;
}
