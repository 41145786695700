.App {
  text-align: center;
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 60%;
  margin: 20px auto;
}

.profile-photo {
  height: 150px;
  width: 150px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .content-container {
    width: 95%;
    text-align: left;
  }
}
