.experience-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: Gupter;
  font-size: large;
  line-height: 25px;
  text-align: justify;
}

@media (max-width: 768px) {
  .experience-container {
    text-align: left;
    font-size: medium;
  }
}

.experience {
  background-color: #cce0ff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.accordion-content {
  overflow: hidden;
  transition: max-height 0.7s ease-in, opacity 0.6s ease-in;
  max-height: 0;
  opacity: 0;
}

.accordion-content.expanded {
  max-height: 1000px; /* Adjust as needed */
  opacity: 1;
}

.accordion-inner {
  padding: 0 30px;
  transition: padding 0.7s ease-in;
}

.accordion-content.expanded .accordion-inner {
  padding: 30px;
}

@media (max-width: 768px) {
  .accordion-content.expanded .accordion-inner {
    padding-top: 10px;
  }
}

.experience-title {
  text-decoration: underline;
  font-size: larger;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .experience-title {
    font-size: large;
  }
}

.accordion-button,
h6 {
  font-family: Gupter;
  font-size: large;
  margin: 0;
}

@media (max-width: 768px) {
  .accordion-button {
    font-size: medium;
  }
}

.accordion-button {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(to right, #e996fa, #8cb8fa, #b576f5);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  border: none;
  cursor: pointer;
  padding: 10px;
  text-align: left;
  border-radius: 5px;
  color: #000000; /* Set the default text color to black */
  -webkit-appearance: none; /* Disable default styling on iOS */
  -moz-appearance: none; /* Disable default styling on Firefox */
  appearance: none; /* Standard property */
}

.accordion-button .icon,
.accordion-button span,
.accordion-button h6 {
  color: #000000; /* Ensure icon and text are black */
}

.accordion-button:hover {
  background: linear-gradient(to right, #b576f5, #8cb8fa, #e996fa);
  border-radius: 5px;
  color: #000000; /* Maintain black color on hover */
}

.accordion-button:focus,
.accordion-button:active {
  color: #000000; /* Ensure black color on focus/active */
  outline: none; /* Remove default outline */
}

.icon {
  margin-left: 10px;
}

.company {
  margin-left: 10px;
  color: #5e338a;
}

.company a {
  text-decoration: none; /* Remove underline by default */
  color: inherit; /* Inherit the color from the parent element */
}

.company a:hover {
  text-decoration: underline; /* Add underline on hover */
}

.role,
.company {
  text-align: left;
  font-size: large;
}

@media (max-width: 768px) {
  .role .company {
    font-size: large;
    text-align: left;
    padding: 0;
  }
}

.tasks {
  font-size: large;
}

@media (max-width: 768px) {
  .tasks {
    font-size: medium;
    padding: 0 3px;
  }
}
