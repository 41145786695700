.education-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: Gupter;
  font-size: large;
  line-height: 25px;
  text-align: justify;
}

@media (max-width: 768px) {
  .education-container {
    text-align: left;
    font-size: medium;
  }
}

.education-title {
  text-decoration: underline;
  font-size: larger;
  margin-bottom: 0px;
}

@media (max-width: 768px) {
  .education-title {
    font-size: large;
  }
}

.university {
  margin-left: 10px;
  color: #5e338a;
}

.result {
  margin: 0;
}

.degree {
  margin: 0;
  font-weight: bold;
}
