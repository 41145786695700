.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, #e996fa, #8cb8fa, #b576f5);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  margin: 0;
  height: 40px;
  padding: 0 15px;
}
.logo {
  font-family: Sankofa Display;
  font-weight: bolder;
  font-size: 24px;
  text-decoration: none;
  color: inherit;
}

.logo:hover {
  color: antiquewhite;
}

.center-section,
.media {
  gap: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.linkedInIcon:hover,
.gitHubIcon:hover {
  color: #920aae;
}
.name {
  font-family: Gupter;
  font-weight: bold;
  font-size: larger;
  text-align: center;
  margin: 0;
}
.role {
  font-family: Gupter;
  font-weight: lighter;
  font-size: medium;
  text-align: center;
  margin: 0;
}
/* Media query for small and extra small screens */
@media (max-width: 768px) {
  .center-section {
    display: none;
  }
}
