.footer-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  /* background: linear-gradient(to right, #e996fa, #8cb8fa, #b576f5); */
  /* background-color: #c1d7f9; */
  background: linear-gradient(to right, #f4cffb, #b8d2f9, #dec0fc);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  margin: 0;
  height: fit-content;
}

.footer-text {
  font-family: Gupter;
  font-weight: bold;
  margin: 0;
  padding-top: 10px;
}
.thankyou-photo {
  height: 120px;
  width: 130px;
}
